<template>
  <v-chart autoresize v-if="chartData" :options="chartOptions" />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";

export default {
  name: "Chart",
  props: ["chartData"],
  components: {
    "v-chart": ECharts,
  },
  data: () => ({
    // ...
  }),
  computed: {
    chartOptions() {
      return {
        // tooltip: {
        //   trigger: "item",
        //   formatter: "{b}: {c} ({d}%)"
        // },
        legend: {
          orient: "vertical",
          x: "center",
          y: "bottom",
        },
        series: [
          {
            type: "pie",
            radius: "66%",
            center: ["50%", "47%"],
            label: {
              normal: {
                // show: false,
                //formatter: '{b} - {c} ({d}%)'
                formatter: "{d}%",
              },
            },
            data: this.chartData,
            itemStyle: {
              emphasis: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  },
};
</script>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
  width: 100%;
  height: 100%;
}
</style>
